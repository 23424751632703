ul {
  background: white;
  list-style: none;
  margin: 0;
  padding-left: 0;
}

li {
  color: black;
  background: white;
  display: block;
  float: left;
  padding: 0.5rem;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;
}

li:hover,
li:focus-within {
  background: #33b5e5;
  opacity: 1;
  cursor: pointer;
}

ul li ul {
  background: white;
  min-width: 8rem;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 0.5rem;
  left: 0;
  display: none;
  visibility: hidden;
  opacity: 0;
}

ul li:hover > ul,
ul li:focus-within > ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

ul li ul li {
  clear: both;
  width: 100%;
}

.link-button {
  font-size: medium;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
  outline: none;
}

.file-upload {
  visibility: hidden;
  display: none;
  position: absolute;
  height: 0;
}
