body {
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
Some temporary, "emergency" styling to make GeoJSON Studio presentable when
screen width is less than 929 px.
*/
@media (max-width: 929px) {
  #geojsonEditor {
    display: none;
    width: 0px !important;
  }
  .mapboxgl-map {
    width: 100vw !important;
  }
  .mapboxgl-ctrl-geocoder {
    display: none !important;
  }
}
